import React, { Component } from 'react';

//Custom Components
import { Spin } from '../Common/Utilities/Spin';
import Login from './Login'


import { Box, Stack, Grid, Tooltip } from '@mui/material'
import Typography from '@mui/material/Typography';



//Database Services
import { getPage, getContactNotificationRecipients} from '../DataServices/WebPageService'
import { getPhotoThumb } from '../DataServices/PhotoService'
import { getBlog } from '../DataServices/BlogService'
import { getEventInfo } from '../DataServices/EventService'
import { getSiteData } from '../DataServices/SiteDataService'
import { getDocument, } from '../DataServices/DocumentService';

export class Landing extends Component {
    constructor(props) {
        super(props);
        this.state = {
            scrolledDown: false,
            showContactForm: false,
            showLogin: false,
            showDocumentViewer: false,
            height: 0,
            width: 0,
            anchorEl: null,
        }
    }
    updateWindowDimensions = () => {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }
    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
        this.getSiteData();
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }
    getSiteData = async () => {
        if (this.props.siteID) {
            const site = await getSiteData(this.props.siteID);
            if (site) {
                this.setState({ site });
                document.title = site.pageTitle;
            }
            else {
                this.setState({ loadError: true });
            }
        }
        this.setState({ loadError: true });
    }


    //Data fetchers
    getWebPage = async () => {
        const data = await getPage(this.props.webPageID);
        if (data === 'error') {
            this.props.setToken('');
            return;
        }
        if (data) {
            this.setState({ webPage: data });
            this.loadPageContent();
        }

    }
    loadPageContent = async () => {
        if (this.state.webPage && this.state.webPage.sections && this.state.webPage.sections.length > 0) {
            for (var i = 0; i < this.state.webPage.sections.length; i++) {
                let section = this.state.webPage.sections[i];
                if (section.content && section.content.length > 0) {
                    for (var j = 0; j < section.content.length; j++) {
                        let content = section.content[j];
                        if (content.refID) {
                            const type = content.contentTypeID;
                            switch (type) {
                                case 4:
                                    const data = await getBlog(content.refID);
                                    if (data) {
                                        const loadedData = await this.loadBlogSpecificContent(data);
                                        const webPage = this.state.webPage;
                                        webPage.sections[i].content[j].blogContent = loadedData;
                                        this.setState({ webPage });

                                    }
                                    break;
                                case 8:

                                    const edata = await getEventInfo(content.refID);

                                    if (edata) {
                                        const eloadedData = await this.loadEventSpecificContent(edata);
                                        const ewebPage = this.state.webPage;
                                        ewebPage.sections[i].content[j].eventContent = eloadedData;
                                        this.setState({ webPage: ewebPage });

                                    }
                                    break;
                                default:
                                    break;

                            }
                        }

                    }

                }
            }
        }
    }


    //Render Funcs

    content = () => {
        return (
            <>


                {this.login()}

            </>
        );
    }



    siteNotFound = () => {
        return (
            <Box sx={{ height: "100vh", width: "100%", display: 'flex', justifyContent: "center" }}>
                <Typography variant="h3" sx={{ fontFamily: this.state.site.fontFamily, textAlign: "center", pt: 20, color: "#eee" }}>
                    Site Not Found
                </Typography>
            </Box>
        )
    }
    login = () => {

            return (
                <Box sx={{ paddingTop: { xs: "56px", sm: "64px" }, minHeight: "100vh", height: "100%", }} className={this.props.dark && "dark"} >
                    <Login
                        appIdentifier={this.props.siteID}
                        setUserInfo={this.props.setUserInfo}
                        setToken={this.props.setToken}
                        logo={this.state.site.clientPortal.loginLogo}
                        buttonColor={this.state.site.clientPortal.buttonColor}
                        accountRequestMessage={this.state.site.clientPortal.accountRequestMessage}
                    />
                </Box>



            )

    }


    loadingSpinner = () => {
        return (
            <Box sx={{ height: "100vh", width: "100%", display: 'flex', justifyContent: "center" }}>
                <Spin open={!this.state.site && !this.state.loadError} message="Loading..." />
            </Box>
        )
    }
    spacer = (y) => {
        return (<Box sx={{ height: { xs: 0, sm: y } }} />)
    }
    staticSpacer = (y) => {
        return (<Box sx={{ height: { xs: y } }} />)
    }

    render() {
        if (this.state.site) {
            return this.content();
        } else {
            if (this.state.loadError) 
                return this.siteNotFound();           
        }
        return this.loadingSpinner();
    }
}

