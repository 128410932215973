import React, { useState } from 'react';

//Custom
import { Spin } from "../Common/Utilities/Spin"
import { ModalDialog } from '../Common/Utilities/ModalDialog'
import { SectionFieldGroup } from '../Common/Utilities/SectionFieldGroup'
import { TextInput } from '../Common/Utilities/TextInput'

//MUI
import PropTypes from 'prop-types';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';

import { validateEmail } from '../DataServices/Utility'
import { createPasswordResetRequest } from '../DataServices/UserService'
import { sendEmail } from '../DataServices/EmailService'
import Linkify from 'react-linkify';

async function loginUser(credentials) {
    return fetch('home/login', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(credentials)
    })
        .then(data =>  data.json() )
}
async function registerUser(credentials) {
    return fetch('home/register', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(credentials)
    })
        .then(data => data.json())
}
async function addEmail(email) {

    const response = await fetch('home/addemail?type=' + 0 + '&email=' + email + '&primary=' + true, { method: 'POST' })
    const id = await response.text();
    return id;
}
function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright � '}
            <Link color="inherit" href="http://simplebluetech.com">
                LiveSphere
      </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

export default function Login({ setToken, setUserInfo, appIdentifier, logo, buttonColor, accountRequestMessage }) {

    const [username, setUserName] = useState();
    const [password, setPassword] = useState();
    const [loggingIn, setLoggingIn] = useState(false);
    const [firstname, setFirstName] = useState();
    const [lastname, setLastName] = useState();
    const [showRequiredAlert, setShowRequiredAlert] = useState(false);
    const [showDupAlert, setDupAlert] = useState(false);
    const [showInvalidAlert, setInvalidAlert] = useState(false);
    const [newUser, setNewUser] = useState(false);
    const [showForgotPwd, setShowForgotPwd] = useState(false);
    const [showForgotPwdEmailSent, setShowForgotPwdEmailSent] = useState(false);
    const [showInvalidEmailAlert, setShowInvalidEmailAlert] = useState(false);



    const handleLoginSubmit = e => {
        e.preventDefault();
        setLoggingIn(true);
        Login();

    }
    const Login = async () => {
        const loginResult = await loginUser({
            username,
            password,
            AppID : appIdentifier
        });
        if (loginResult.success) {
            const token = loginResult.accessToken;
            setUserInfo(loginResult);
            setToken(token);
        }
        else {
            const token = ''
            setToken(token);
            setLoggingIn(false);
            setInvalidAlert(true);
        }
    }
    const handleRegisterSubmit = async e => {
        e.preventDefault();
        setToken("");
        if (showInvalidEmailAlert) {
            return;
        }
        if(!username || !password || !firstname || !lastname) {
            setShowRequiredAlert(true);
            return;
        }
        const loginResult = await registerUser({
            username,
            password,
            firstname,
            lastname,
            appIdentifier
        });
        if (loginResult.accessToken === "Duplicate") {
            setDupAlert(true);
        }
        else {
            await addEmail(username);
            const token = loginResult.accessToken
            setUserInfo(loginResult);
            setToken(token);
        }
    }
    const handleNewUser = () => {
        setNewUser(true);
        setShowInvalidEmailAlert(false);

    }
    const handleExistingUser = () => {
        setNewUser(false);
        setShowInvalidEmailAlert(false);

    }
    const handleLoginUserNameChange = async e => {
        e.preventDefault();
        const email = e.target.value;

        setInvalidAlert(false);
        setUserName(email);
        setShowInvalidEmailAlert(false);

    }

    const handleLoginUserNameBlur = async e => {
        e.preventDefault();
        const email = e.target.value;
        if (validateEmail(email)) {
            setUserName(email);
            setShowInvalidEmailAlert(false);
            setDupAlert(false);
        }
        else {
            setShowInvalidEmailAlert(true);
        }
    }
    const handleLoginPasswordChange = async e => {
        e.preventDefault();
        setPassword(e.target.value);
        setShowRequiredAlert(false);
        setInvalidAlert(false);
    }
    const handleNewUserNameBlur = async e => {
        e.preventDefault();
        const email = e.target.value;
        if (validateEmail(email)) {
            setUserName(email);
            setShowInvalidEmailAlert(false);
            setDupAlert(false);
        }
        else {
            setShowInvalidEmailAlert(true);
        }
    }
    const handleNewUserNameChange = async e => {
        e.preventDefault();
        const email = e.target.value;
        setShowRequiredAlert(false);
        setShowInvalidEmailAlert(false);
        setDupAlert(false);
        setUserName(email);
    }
    const handleFirstNameChange = async e => {
        e.preventDefault();
        setFirstName(e.target.value);
        setShowRequiredAlert(false);
        setDupAlert(false);
    }
    const handleLastNameChange = async e => {
        e.preventDefault();
        setLastName(e.target.value);
        setShowRequiredAlert(false);
        setDupAlert(false);
    }
    const handleSubmitForgotPwd = async e => {
        const reqID = await createPasswordResetRequest(username);
        //const response = await sendEmail(username, "", 'Password Reset', 'https://localhost:44377/passwordreset/' + reqID, '', appIdentifier);
        let h = "<div style='padding:12px'>";
        h += "<img src='https://mylivesphere.com/Images/lsletters.png' height='40px' title='LiveSphere'>";
        h += "</div>";
        h += "<div style='padding:48px'>";
        h += "<h1>Password Reset</h1>";
        h += "<h5>Click below to reset password</h5>";
        h += "<a href='" + 'https://mylivesphere.com/passwordreset/' + reqID + "'>Reset</a>";
        h += "</div>";
        const response = await sendEmail(username, "", 'Password Reset', h + reqID, '', appIdentifier);
        console.log(response);
        setShowForgotPwdEmailSent(true);
    }

    const forgotPwd = () => {
        return (
            <ModalDialog
                dark={false}
                show={showForgotPwd}
                title="Forgotten Password?"
                subTitle={!showForgotPwdEmailSent && "No problem!  Simply enter your email to receive password reset instructions."}
                handleClose={() => setShowForgotPwd(false)}
                handleSave={!showForgotPwdEmailSent && handleSubmitForgotPwd}
                saveLabel={!showForgotPwdEmailSent ? "Submit" : "Close"}
                fullWidth={true}
                maxWidth="xs"
                bold={true}
            >
                <SectionFieldGroup dark={true}>

                    {showForgotPwdEmailSent ?
                        <Alert variant="filled" severity="info">{"Password reset instructions send to " + username}</Alert>
                        :
                        <TextInput
                            dark={true}
                            cKey="fpemail"
                            cid="fpemail"
                            id="fpemail"
                            label="Email"
                            onChange={handleLoginUserNameChange}
                        />
                    }
                </SectionFieldGroup>
            </ModalDialog>
            
            )
    }

    let contents = 
    <>
            {!newUser
                ?
                <Box
                    component="form"
                    noValidate
                    onSubmit={handleLoginSubmit}
                    sx={{ my: 1, p: 3, py: 1 }}
                >
                    {logo ? 
                        <Box sx={{width:"100%", textAlign:"center", pb:2}}>
                            <img src={logo} style={{ height: "80px", width: "auto", maxWidth:"100%",objectFit: "contain", margin: "auto" }} />
                        </Box>

                        :
                        <Avatar sx={{ m: 1, bgcolor: 'secondary.main', marginLeft: "auto", marginRight: "auto" }}>
                            <LockOutlinedIcon />
                        </Avatar>
                        }

                    <Typography component="h4" variant="h6" sx={{ color: "#bbb", textAlign: "center" }}>
                        Client Portal Login
                    </Typography>
                    <TextField
                        margin="normal"
                        variant="standard"
                        required
                        fullWidth
                        size="small"
                        id="email"
                        label="Email Address"
                        placeholder="Email Address"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        defaultValue=""
                        onBlur={handleLoginUserNameBlur}
                        onChange={handleLoginUserNameChange}
                        InputProps={{ sx: { color: "#111" } }}
                        InputLabelProps={{ style: { color: "#111" } }}
                    />
                    {showInvalidEmailAlert && <Alert severity="error">Invalid email format.</Alert>}
                    <TextField
                        margin="normal"
                        required
                        variant="standard"
                        fullWidth
                        size="small"
                        name="password"
                        label="Password"
                        placeholder="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        onChange={handleLoginPasswordChange}
                        InputProps={{ sx: { color: "#111" } }}
                        InputLabelProps={{ style: { color: "#111" } }}
                    />
                    {showInvalidAlert && <Alert severity="error">Invalid username or password.  Please try again.</Alert>}
                    <Button
                        type="submit"
                        fullWidth
                        color="secondary"
                        variant="contained"
                        sx={{ mt: 3, mb: 2, boxShadow: "none", backgroundColor: buttonColor }}
                    >
                        Sign In
                    </Button>
                    <Box sx={{ width: "100%", textAlign:"center" }}>
                    <Button variant="link" sx={{ color: "#999", my:1, mx:"auto"}} onClick={() => setShowForgotPwd(!showForgotPwd)}>
                        Forgot password?
                    </Button>
                    </Box>
                    <Box sx={{ width: "100%", textAlign: "center" }}>
                        <Linkify componentDecorator={(decoratedHref, decoratedText, key) => (<a target="blank" href={decoratedHref} key={key}> {decoratedText} </a>)}>
                        <Typography variant="subtitle2" sx={{ fontHeight:".7rem", color: "#bbb", fontWeight:400, textAlign: "center" }}>
                            {accountRequestMessage}
                            </Typography>
                        </Linkify>
                    </Box>
                </Box>
                :
                <Box
                    component="form"
                    noValidate
                    onSubmit={handleRegisterSubmit}
                    sx={{
                        my: 1,

                        padding: "24px",
                    }}>
                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main', marginLeft: "auto", marginRight: "auto" }}>
                        <LockOpenOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5" sx={{ color: "black", textAlign: "center" }}>
                        Sign up for new account
                    </Typography>
                    <TextField
                        autoComplete="new-password"
                        margin="normal"
                        variant="standard"
                        required
                        fullWidth
                        size="small"
                        id="newemail"
                        label="Email Address"
                        placeholder="Email Address"
                        name="newemail"
                        autoFocus
                        onBlur={handleNewUserNameBlur}
                        onChange={handleNewUserNameChange}
                        defaultValue=""
                        InputLabelProps={{ style: { color: "#111" } }}
                        InputProps={{ autoComplete: 'new-password', sx: { color: "#111" } }}
                    />
                    {showInvalidEmailAlert && <Alert severity="error">Invalid email format.</Alert>}
                    <TextField
                        autoComplete="new-password"
                        margin="normal"
                        required
                        variant="standard"
                        fullWidth
                        size="small"
                        name="rpassword"
                        label="Password"
                        placeholder="Password"
                        type="password"
                        id="newpassword"
                        onChange={handleLoginPasswordChange}
                        defaultValue=""
                        InputLabelProps={{ style: { color: "#111" } }}
                        InputProps={{ autoComplete: 'new-password', sx: { color: "#111" } }}
                    />
                    <TextField
                        autoComplete="given-name"
                        margin="normal"
                        variant="standard"
                        required
                        fullWidth
                        size="small"

                        label="First Name"
                        placeholder="First Name"
                        name="rfirstname"
                        onChange={handleFirstNameChange}
                        defaultValue=""
                        InputLabelProps={{ style: { color: "#111" } }}
                        InputProps={{ autoComplete: 'given-name', sx: { color: "#111" }, form: { autoComplete: 'given-name' } }}
                    />
                    <TextField
                        autoComplete="new-password"
                        margin="normal"
                        variant="standard"
                        required
                        fullWidth
                        size="small"
                        id="newlastname"
                        label="Last Name"
                        placeholder="Last Name"
                        name="rlastname"
                        onChange={handleLastNameChange}
                        defaultValue=""
                        InputLabelProps={{ style: { color: "#111" } }}
                        InputProps={{ autoComplete: 'new-password', sx: { color: "#111" } }}

                    />
                    {showRequiredAlert && <Alert severity="error">All fields are required.</Alert>}
                    {showDupAlert && <Alert severity="error">Email is associated to an existing LiveSphere account.  If this is your email, please sign in.  Otherwise, use a different email address.</Alert>}
                    <Button
                        type="submit"
                        fullWidth
                        color="secondary"
                        variant="contained"
                        sx={{ mt: 3, mb: 2, boxShadow: "none" }}
                    >
                        Sign In
                    </Button>
                    <Grid container >
                        <Grid item xs>
                            <p />
                        </Grid>
                        <Grid item>
                            <Button onClick={() => handleExistingUser()} sx={{ color: "black" }}>
                                {"Already have an account? Sign in"}
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            }
        </>


             

    return (
        <Box sx={{minWidth: "400px", width:"50%", mx:"auto"} }>
            {contents}
            {forgotPwd()}
            <Spin open={loggingIn} />
        </Box>
    );
}


Login.propTypes = {
    setToken: PropTypes.func.isRequired
};